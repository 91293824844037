// import { test } from './modules/test';
// test();

// aタグのアニメーション
const smoothScrollTrigger = document.querySelectorAll('a[href^="#"]');
for (let i = 0; i < smoothScrollTrigger.length; i++){
  smoothScrollTrigger[i].addEventListener('click', (e) => {
    e.preventDefault();
    let href = smoothScrollTrigger[i].getAttribute('href');
    let targetElement = document.getElementById(href.replace('#', ''));
    const rect = targetElement.getBoundingClientRect().top;
    const offset = window.pageYOffset;
    const headerHeight = 140;
    const target = rect + offset - headerHeight;
    window.scrollTo({
      top: target,
      behavior: 'smooth',
    });
  });
}

window.onload = function () {
  const url = window.location.hash
  let targetElement = document.getElementById(url.replace('#', ''));
  if (targetElement) {
    const rect = targetElement.getBoundingClientRect().top;
    const offset = window.pageYOffset;
    const headerHeight = 140;
    const target = rect + offset - headerHeight;
    window.scrollTo({
      top: target,
      behavior: 'smooth',
    });
  }
}

// 全li取得
let lists = document.querySelectorAll('li');
for(let i=0;i<lists.length;i++){
    // 全liそれぞれに対する「クリックされたとき」の処理
    lists[i].addEventListener('click',function(){
        // クリックされたliの子要素 aタグのリンク先を取得
        let href = this.querySelector('a').getAttribute('href');
        // 現在のタブで開く
        location.href = href;
        // 新しいタブで開く
        window.open(href);
    })
}